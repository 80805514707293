// PartnersData.js
import pabna from "../../Image/partners/pabna.png";
import uniqueHuman from "../../Image/partners/unique_human.png";
import cryptoWally from "../../Image/partners/Crypto_wally.png";
import partner from "../../Image/partners/partner.png";
import partner1 from "../../Image/partners/partner-1.png";
import partner2 from "../../Image/partners/partner-2.png";
import partner3 from "../../Image/partners/partner-3.png";
import partner5 from "../../Image/partners/partner-5.png";
import partner6 from "../../Image/partners/partner-6.png";
import partner8 from "../../Image/partners/partner-8.png";
import partner12 from "../../Image/partners/partner-12.png";
import partner13 from "../../Image/partners/partner-13.png";
import partner14 from "../../Image/partners/partner-14.png";
import partner15 from "../../Image/partners/partner-15.png";
import partner18 from "../../Image/partners/partner-18.png";
import partner21 from "../../Image/partners/partner-21.png";
import partner22 from "../../Image/partners/partner-22.png";
import partner24 from "../../Image/partners/partner-24.png";
import partner25 from "../../Image/partners/partner-25.png";
import partner26 from "../../Image/partners/partner-26.png";
import partner27 from "../../Image/partners/partner-27.png";
import partner28 from "../../Image/partners/partner-28.png";
import partner29 from "../../Image/partners/partner-29.png";
import partner30 from "../../Image/partners/partner-30.png";
import partner31 from "../../Image/partners/partner-31.png";
import partner32 from "../../Image/partners/partner-32.png";
import partner33 from "../../Image/partners/partner-33.png";
import partner36 from "../../Image/partners/partner-36.png";
import partner40 from "../../Image/partners/partner-40.png";
import partner72 from "../../Image/partners/partner-72.png";
import partner73 from "../../Image/partners/partner-73.png";
import partner74 from "../../Image/partners/partner-74.png";
import partner34 from "../../Image/partners/partner-34.png";
import partner35 from "../../Image/partners/partner-35.png";
import partner42 from "../../Image/partners/partner-42.jpeg";
import partner54 from "../../Image/partners/partner-54.png";
import partner45 from "../../Image/partners/partner-45.png";
import partner46 from "../../Image/partners/partner-46.png";
import partner48 from "../../Image/partners/partner-48.png";
import partner50 from "../../Image/partners/partner-50.png";
import partner51 from "../../Image/partners/partner-51.png";
import partner53 from "../../Image/partners/partner-53.png";
import partner55 from "../../Image/partners/partner-55.png";
import partner57 from "../../Image/partners/partner-57.png";
import partner61 from "../../Image/partners/partner-61.png";
import partner63 from "../../Image/partners/partner-63.png";
import partner64 from "../../Image/partners/partner-64.png";
import partner65 from "../../Image/partners/partner-65.png";
import mateico from "../../Image/partners/mateico.png";
import metabloqs from "../../Image/partners/metabloqs.png";
import bulk from "../../Image/partners/bulk.png";
import partner66 from "../../Image/partners/partner-66.png";
import partner69 from "../../Image/partners/partner-69.png";
import partner60 from "../../Image/partners/partner-60.png";
import partner67 from "../../Image/partners/partner-67.png";
import partner52 from "../../Image/partners/partner-52.png";
import partner59 from "../../Image/partners/partner-59.png";
import partner70 from "../../Image/partners/partner-70.png";
import partner43 from "../../Image/partners/partner-43.png";
import partner71 from "../../Image/partners/partner-71.png";
import partner41 from "../../Image/partners/partner-41.png";
import partner49 from "../../Image/partners/partner-49.png";
import partner56 from "../../Image/partners/partner-56.png";

const partners = [
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: pabna,
    brandName: ""
  },
  {
    wrapperClass: "card_clas_parner d-flex align-items-center justify-content-center",
    imgClassName: "img-fluid",
    logo: uniqueHuman,
    brandName: "",
    url: "https://www.uniquehuman.org/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: cryptoWally,
    brandName: "",
    url: "https://www.cryptowaley.com/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner,
    brandName: "",
    url: "https://www.alketbilaw.com/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner1,
    brandName: "",
    url: "https://www.indialaw.in/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner2,
    brandName: "",
    url: "https://nrivision.com/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner3,
    brandName: "",
    url: "https://www.mhssce.ac.in/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner5,
    brandName: "",
    url: "https://thenewscrypto.com/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner6,
    brandName: "",
    url: "https://supra.com/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner8,
    brandName: "",
    url: "https://www.travala.com/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner12,
    brandName: "",
    url: "https://www.blockdegree.org/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner13,
    brandName: "",
    url: "https://www.yieldteq.io/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner14,
    brandName: "",
    url: "https://choise.com/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner15,
    brandName: "",
    url: "https://voy.finance/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner18,
    brandName: "",
    url: "https://dex.globiance.com/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner21,
    brandName: "",
    url: "https://x.com/impel_official?lang=en"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner22,
    brandName: "",
    url: "https://www.tradefinex.org/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner24,
    brandName: "",
    url: "https://www.cyclebit.com/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner25,
    brandName: "",
    url: "https://www.coinsbee.com/en/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner26,
    brandName: "",
    url: "https://fathom.fi/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner27,
    brandName: "",
    url: "https://yodaplus.com/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner28,
    brandName: ""
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner29,
    brandName: "",
    url: "https://plugin.global/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner30,
    brandName: "",
    url: "https://docs.avisagamesguild.com/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner31,
    brandName: "",
    url: "https://razor.network/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner32,
    brandName: "",
    url: "https://www.xdc.dev/valid/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner33,
    brandName: "",
    url: "https://www.trcl.org.in/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "img-fluid",
    logo: partner36,
    brandName: ""
  },
  {
    wrapperClass: "card_clas_parner d-flex align-items-center justify-content-center",
    imgClassName: "img-fluid",
    logo: partner40,
    brandName: "",
    url: "https://www.indiablockchainmonth.com/"
  },
  {
    wrapperClass: "card_clas_parner d-flex align-items-center justify-content-center",
    imgClassName: "partner-img-w",
    logo: partner72,
    brandName: "",
    url: "https://metatrust.io/"
  },
  {
    wrapperClass: "card_clas_parner d-flex align-items-center justify-content-center",
    imgClassName: "partner-img-w",
    logo: partner73,
    brandName: "",
    url: "https://metamorphosis.octaloop.com/"
  },
  {
    wrapperClass: "card_clas_parner d-flex align-items-center justify-content-center",
    imgClassName: "partner-img-w",
    logo: partner74,
    brandName: "",
    url: "https://www.casemine.com/"
  },
  {
    wrapperClass: "card_clas_parner d-flex align-items-center justify-content-center partner-content",
    imgClassName: "img-fluid",
    logo: partner34,
    brandName: ""
  },
  {
    wrapperClass: "card_clas_parner d-flex align-items-center justify-content-center partner-content",
    imgClassName: "img-fluid",
    logo: partner35,
    brandName: "",
    url: "https://ledgermail.io/"
  },
  {
    wrapperClass: "card_clas_parner d-flex align-items-center justify-content-center partner-content",
    imgClassName: "partner-img-w",
    logo: partner42,
    brandName: "",
    url: "https://x.com/web3aligarh/"
  },
  {
    wrapperClass: "card_clas_parner d-flex align-items-center justify-content-center partner-content",
    imgClassName: "partner-img-w",
    logo: partner54,
    brandName: "",
    url: "https://xdc.org/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "partner-img-w",
    logo: partner45,
    brandName: "BlocksScan",
    url: "https://xdc.network/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "partner-img-w",
    logo: partner46,
    brandName: "Butterfly Protocol",
    url: "https://x.com/butterflyproto/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "partner-img-w",
    logo: partner48,
    brandName: "Prime Numbers",
    url: "https://primenumbers.xyz/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "partner-img-w",
    logo: partner50,
    brandName: "Stasis",
    url: "https://stasis.net/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "partner-img-w",
    logo: partner51,
    brandName: "StorX",
    url: "https://storx.tech/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "partner-img-w",
    logo: partner53,
    brandName: "XcelTrip",
    url: "https://xceltrip.com/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "partner-img-w",
    logo: partner55,
    brandName: "XDCNFT",
    url: "https://app.xdcnft.com/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "partner-img-w",
    logo: partner57,
    brandName: "XSwap Protocol",
    url: "https://xspswap.finance/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "partner-img-w",
    logo: partner61,
    brandName: "Crescite",
    url: "https://crescite.org/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "partner-img-w",
    logo: partner63,
    brandName: "Datachain",
    url: "https://datachain.one"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "partner-img-w",
    logo: partner64,
    brandName: "DIMO Finance",
    url: "https://www.dimo.finance/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "partner-img-w",
    logo: partner65,
    brandName: "Ecoin"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "partner-img-w",
    logo: mateico,
    brandName: "Mateico",
    url: "https://mateico.io/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "partner-img-w",
    logo: metabloqs,
    brandName: "Metabloqs",
    url: "https://metabloqs.com/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "partner-img-w",
    logo: bulk,
    brandName: "Token Bulksender",
    url: "https://bulksender.app/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "partner-img-w",
    logo: partner66,
    brandName: "Fathom",
    url: "https://fathom.fi/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "partner-img-w",
    logo: partner69,
    brandName: "LedgerFi",
    url: "https://ledgerfi.io/"
  },
  {
    wrapperClass: "card_clas_parner align-items-center",
    imgClassName: "custom-min-width",
    logo: partner60,
    brandName: "ComTech Gold",
    url: "https://comtechgold.com/"
  },
  {
    wrapperClass: "card_clas_parner d-flex align-items-center justify-content-center partner-content",
    imgClassName: "img-fluid",
    logo: partner67,
    brandName: "WeFi",
    url: "https://www.wefi.co/"
  },
  {
    wrapperClass: "card_clas_parner d-flex align-items-center justify-content-center partner-content",
    imgClassName: "img-fluid",
    logo: partner52,
    brandName: "",
    url: "https://swiftcourt.com/en"
  },
  {
    wrapperClass: "card_clas_parner d-flex align-items-center justify-content-center partner-content",
    imgClassName: "img-fluid",
    logo: partner59,
    brandName: "Circularity Finance",
    url: "https://www.circularity.finance/"
  },
  {
    wrapperClass: "card_clas_parner d-flex align-items-center justify-content-center partner-content",
    imgClassName: "img-fluid",
    logo: partner70,
    brandName: "The Nexus",
    url: "https://www.thenexusportal.io/"
  },
  {
    wrapperClass: "card_clas_parner d-flex align-items-center justify-content-center partner-content",
    imgClassName: "img-fluid",
    logo: partner43,
    brandName: "0xino",
    url: "https://x.com/0xinoio?lang=en"
  },
  {
    wrapperClass: "card_clas_parner d-flex align-items-center justify-content-center partner-content",
    imgClassName: "img-fluid",
    logo: partner71,
    brandName: "",
    url: "https://www.xdc.dev/jurjeesahamed/how-to-connect-xdc-pay-wallet-chrome-extension-with-web3modal-5gfh"
  },
  {
    wrapperClass: "card_clas_parner d-flex align-items-center justify-content-center partner-content",
    imgClassName: "partner-img-w",
    logo: partner41,
    brandName: "",
    url: "https://www.punedao.xyz/"
  },
  {
    wrapperClass: "card_clas_parner  d-flex align-items-center justify-content-center partner-content",
    imgClassName: "img-fluid",
    logo: partner49,
    brandName: "PrimePort",
    url: "https://primeport.xyz/"
  },
  {
    wrapperClass: "card_clas_parner",
    imgClassName: "partner-img-w",
    logo: partner56,
    brandName: "",
    url: "https://xdsea.com/"
  }
];

export default partners;