import React, {useState, useEffect} from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const pages = [
  {
    name: "AI Legal Documents",
    link: "/ai-legal-document-generator",
  },
  {
    name: "Smart Legal Contracts",
    link: "/smart-legal-contracts",
  },
  {
    name: "Digital Signature",
    link: "/digital-signature",
  },
  {
    name: "Uploading Blockchain",
    link: "/secure-document-storage-blockchain",
  },
  {
    name: "AI Chat",
    link: "/ai-legal-chat",
  },
  {
    name: "Mediation",
    link: "/Mediation ",
  },
  {
    name: "Arbitration",
    link: "/arbitration",
  },
  {
    name: "Marketplace for Lawyers",
    link: "/laywer-marketplace",
  },
  {name: "About us", link: "/about-us"},
  {name: "Blog", link: "/blog"},
  {name: "Contact us", link: "/contact-us"},
  {name: "Login", link: "https://market.lawblocks.io"},
];

const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const drawerWidth = 240;

  // Retrieve dark mode state from localStorage or default to false (light mode)
  const [darkMode, setDarkMode] = useState(
    () => localStorage.getItem("darkMode") === "true" || false
  );
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    // Apply the initial theme when the component mounts
    document.body.classList.toggle("dark", darkMode);
    document.body.classList.toggle("light", !darkMode);
  }, [darkMode]);

  const toggleDarkMode = () => {
    const newMode = !darkMode;
    setDarkMode(newMode); // Toggle the dark mode state

    // Store the new value in localStorage
    localStorage.setItem("darkMode", newMode);

    // Update the body class to reflect the theme
    document.body.classList.toggle("dark", newMode);
    document.body.classList.toggle("light", !newMode);
  };

  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        open={openDrawer}
        sx={{
          display: {xs: "block", sm: "block"},
          "& .MuiDrawer-paper": {boxSizing: "border-box", width: drawerWidth},
        }}
        className="driwer_ss"
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          <a className="nav_brand header" href="/">
            <img
              src={require("../../Image/banner/dark_logo.webp")}
              className="blackshow header_drawer_icon"
            />
            <img
              src={require("../../Image/banner/dark_logo.webp")}
              className="whiteshow header_drawer_icon"
            />
          </a>
          {pages.map((page, index) => (
            <a
              href={page.link}
              key={index}
              onClick={() => setOpenDrawer(false)}
            >
              <ListItemButton>
                <ListItemIcon>
                  <ListItemText className="text-white">
                    {page.name}
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
            </a>
          ))}
        </List>
      </Drawer>
      <div className="ml-auto d-flex">
        <a
          href="#"
          className="Butn_header dark_change"
          onClick={toggleDarkMode}
        >
          {darkMode ? (
            <i className="ri-moon-line"></i>
          ) : (
            <i className="ri-sun-line"></i>
          )}
        </a>
        <IconButton
          sx={{color: "white", marginLeft: "auto"}}
          onClick={() => setOpenDrawer(!openDrawer)}
          className="bg_drawe"
        >
          <MenuIcon color="white" />
        </IconButton>
      </div>
    </React.Fragment>
  );
};

export default DrawerComp;
