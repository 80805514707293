import React, { useState, useEffect } from "react";
import Header from "../componant/HeaderNew";
import { Button } from "@mui/material";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
// import Bgvedio from "../Image/globe-fiat-block.mp4";

import FAQAccordion from "../componant/FAQAccordionProps";

function Home() {
  const [isScrolled, setIsScrolled] = useState(false);
  // useEffect(() => {
  //   function handleScroll() {
  //     const fourthSection = document.querySelector("#section-4");
  //     const fourthSectionTop = fourthSection.offsetTop;
  //     const currentScrollPosition = window.pageYOffset + window.innerHeight;
  //     if (currentScrollPosition >= fourthSectionTop) {
  //       setIsScrolledToFourthSection(true);
  //     } else {
  //       setIsScrolledToFourthSection(false);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    function handleScroll() {
      const fourthSection = document.querySelector("#section-4");
      const header = document.querySelector("header");
      const { top } = fourthSection.getBoundingClientRect();
      const headerHeight = header.offsetHeight;
      setIsScrolled(top <= headerHeight);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const faqs = [
    {
      question: "How does Law Blocks AI mediation work?",
      answer:
        "Parties in a dispute use Law Blocks AI’s secure platform to engage in confidential discussions with the help of a trained mediator. The process is informal, allowing both parties to explore solutions without going to court.",
    },
    {
      question: "How do I start a mediation process on Law Blocks AI?",
      answer:
        "To initiate mediation, you simply register your case on the platform, upload relevant documents, and select a mediator. Once both parties agree to the process, mediation can begin.",
    },
    {
      question: "Can I choose my mediator on Law Blocks AI?",
      answer:
        "Yes, Law Blocks AI allows users to select mediators from a roster of qualified professionals based on their expertise, ensuring a fair and suitable mediator for your case.",
    },
  ];
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const navigate = () => {
    window.open("https://market.lawblocks.io", "_blank");
  };

  return (
    <div className={isScrolled ? "header scrolled" : "header color_white"}>
      <Header />
      <Helmet>
        <title>Mediation Service | Find Mediator for Legal Service</title>
        <meta
          property="og:title"
          content="Mediation Service | Find Mediator for Legal Service"
        />
        <meta
          property="og:description"
          content="Find a mediator for all your legal needs, divorce mediation, family mediation, or any other mediation service. Find your mediator."
        />
        {/* <!-- Uncomment and replace with actual image URL if available -->
<!-- <meta
  property="og:image"
  content="https://lawblocks.io/assets/mediator-thumbnail.jpg"
/> --> */}
        <meta property="og:url" content="https://lawblocks.io/Mediation " />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Contribute as a Mediator with Law Blocks AI - Flexible and Efficient Mediation"
        />
        <meta
          name="twitter:description"
          content="Discover how you can contribute as a mediator with Law Blocks AI. Our platform provides a modern, AI-powered solution for mediation, connecting clients with expert mediators for a cost-effective and confidential resolution of disputes."
        />
        {/* <!-- Uncomment and replace with actual image URL if available -->
<!-- <meta
  name="twitter:image"
  content="https://lawblocks.io/assets/mediator-thumbnail.jpg"
/> --> */}
      </Helmet>
      <main>
        {/* <SectionWithVideoBackground /> */}
        <section
          className="section_banner_hero new_Section_AIdoc uploaddocs  didgtal_hero bg_image_mediaor"
          id="section-4"
        >
          {/* <div className="purble"></div>
          <div className="yellow"></div>
          <div className="green"></div> */}

          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <div className="hero_seaction_law align-items-start ">
                  <h1
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left mb-2"
                  >
                    Contribute as a Mediator
                  </h1>

                  <p
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left m-0"
                  >
                    Join Law Blocks AI as a legal mediator and turn your
                    expertise into a rewarding experience.
                  </p>
                  <Button
                    onClick={navigate}
                    className="laight_button border_radious_button"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                  >
                    Get Started
                  </Button>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={6}
                className="d-flex align-items-center justify-content-center"
              ></Grid>
            </Grid>
          </Container>
        </section>
        <section className="section_defualt ">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={6}
                  className="d-flex align-item-center"
                >
                  <div
                    className="imag_widtjas image_contain_digital"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      alt=" Contribute as a Mediator with Law Blocks AI"
                      src={require("../../Image/banner/mediaotion2.webp")}
                      className="img-fluid"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>Overview of Mediation</h2>
                    <p className="text-justify">
                      Mediation offers a non-contentious alternative to
                      litigation by fostering negotiation between parties,
                      saving time and costs while preserving confidentiality.
                      Law Blocks AI enhances this process with advanced
                      technology, connecting users to top mediators across
                      various legal fields for efficient dispute resolution.
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>

        <section className="section_defualt bg-gray_section pt-3 pb-4">
          <Container maxWidth="xl" className="mt-5">
            <div className="padding_adjust">
              <Grid container spacing={3} className="justify-content-center">
                <Grid item xs={12} md={12} lg={7}>
                  <div
                    className="overvide align-items-center pl-5 pl-sm-0"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2 className="text-center">
                      Benefits of Law Blocks AI Mediation
                    </h2>
                    <p className="text-center">
                      Law Blocks AI combines expert legal mediators with
                      cutting-edge AI tools to ensure faster, fairer
                      outcomes—without the cost and stress of court proceedings.
                      Key benefits include
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <ul
                    class="benifitlaw-list tranc_xlass card_layput justify-content-center"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <li>
                      <div class="circle">1</div>
                      <div class="text">Efficient Online Mediation</div>
                    </li>
                    <li>
                      <div class="circle">2</div>
                      <div class="text">Expert Mediator Selection</div>
                    </li>
                    <li>
                      <div class="circle">3</div>
                      <div class="text">Cost-Effective Solutions</div>
                    </li>
                    <li>
                      <div class="circle">4</div>
                      <div class="text">AI-Powered Insights</div>
                    </li>
                    <li>
                      <div class="circle">5</div>
                      <div class="text">Confidential and Secure</div>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section
          className="section_defualt pt-3 pb-4"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={6}>
                <div
                  class="features-container pl-0 pr-0 margin_zeeor_mobile"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <div class="feature-box ml-0 flex_align_werp">
                    <div className="circule_radioue">
                      <img
                        alt=" Contribute as a Mediator with Law Blocks AI"
                        src={require("../../Image/banner/login.webp")}
                      />
                    </div>
                    <h3 className="text-center">Sign Up and Log In</h3>
                  </div>

                  <div class="feature-box flex_align_werp ">
                    <div className="circule_radioue">
                      <img
                        alt=" Contribute as a Mediator with Law Blocks AI"
                        src={require("../../Image/banner/lawer.webp")}
                      />
                    </div>
                    <h3 className="text-center">Choose Mediation Option</h3>
                  </div>
                  <div class="feature-box flex_align_werp ml-0">
                    <div className="circule_radioue">
                      <img
                        alt=" Contribute as a Mediator with Law Blocks AI"
                        src={require("../../Image/banner/chat.webp")}
                      />
                    </div>
                    <h3 className="text-center">Choose Mediator Profile</h3>
                  </div>
                  <div class="feature-box  flex_align_werp">
                    <div className="circule_radioue">
                      <img
                        alt=" Contribute as a Mediator with Law Blocks AI"
                        src={require("../../Image/banner/submit.webp")}
                      />
                    </div>
                    <h3 className="text-center">Send Request</h3>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <div
                  className="overvide align-items-start"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <h2 className="text-left none-br">
                    Process of Mediation in <br />
                    Law Blocks AI
                  </h2>
                  <p>
                    The mediation process at Law Blocks AI is designed with
                    simplicity and user accessibility in mind. We understand
                    that legal procedures can often feel overwhelming, so we've
                    streamlined our platform to make it easy for anyone to use.
                    Here’s a step-by-step breakdown of how mediation works
                    within Law Blocks AI
                  </p>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className="section_defualt  why_digilat bag_image_lawer  bg-gray_section">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start "
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>Choose Your Mediator</h2>
                    <p className="text-justify">
                      Law Blocks AI offers a directory of skilled mediators with
                      expertise in various legal areas. Filter by
                      specialization, view profiles, and user reviews to find
                      the right match. Enjoy flexible scheduling and faster
                      resolutions on our online platform.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className=" "
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      src={require("../../Image/banner/lawer_medioar.webp")}
                      className="img-fluid bottin_imag_law"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section className="section_defualt pt-3 pb-5">
          <Container maxWidth="lg" className="mt-5">
            <div className="padding_adjust">
              <Grid container spacing={3} className="justify-content-center">
                <Grid item xs={12} md={12} lg={8}>
                  <div
                    className="overvide align-items-center"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2 className="text-center">Contribute as a Mediator</h2>
                    <p className="text-center">
                      Are you an experienced attorney looking to contribute to
                      the world of online mediation? Law Blocks AI offers you
                      the opportunity to join our mediator panel, where you can
                      help parties resolve disputes in a more amicable and
                      efficient manner.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <ul class="benifitlaw-list justify-content-center color_gray_card">
                    <li>
                      <div class="circle">1</div>
                      <div class="text">Sign Up as an Advocate</div>
                    </li>
                    <li>
                      <div class="circle">2</div>
                      <div class="text">Create Your Profile</div>
                    </li>
                    <li>
                      <div class="circle">3</div>
                      <div class="text"> Begin Mediation</div>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section className="section_defualt  why_digilat bg-gray_section">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start "
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>Why Law Blocks AI for Mediation?</h2>
                    <p>
                      Law Blocks AI enhances online dispute resolution with
                      AI-powered mediation for faster, more accurate case
                      insights. Our user-friendly platform streamlines the
                      process, enabling effective communication and efficient
                      resolutions. Whether you're a mediator or a party in a
                      dispute, Law Blocks AI offers a flexible solution that
                      simplifies mediation.
                    </p>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={6}
                  className="d-flex align-items-center"
                >
                  <div
                    className=" "
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      src={require("../../Image/banner/whymediator.webp")}
                      className="img-fluid"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section
          className="section_defualt bg_selcton_faq"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={6} className=" mb-4">
                <div className=" overvide headeing_main align-items-start justify-content-start">
                  {/* <span className="lable_badge">FAQ</span> */}
                  <h2 className="text-captailz">Frequently asked questions</h2>
                  <p>
                    Here are some common questions and their answers to help you
                    understand how Law Blocks AI can assist you.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={6} className=" mb-4">
                <FAQAccordion faqs={faqs} />
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
      <div></div>
      <Footer />
    </div>
  );
}

export default Home;
