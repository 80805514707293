import React, {useEffect, useState} from "react";
import {
  AppBar,
  Button,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import DrawerComp from "./DrawerNew";
import {Link} from "react-router-dom";
import HoverMenu from "./HoverMenu";
import axios from "axios";

const Header = () => {
  const [value, setValue] = useState();
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "true" // Initialize from localStorage
  );
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode; // Toggle the dark mode state
    setDarkMode(newDarkMode);
    localStorage.setItem("darkMode", newDarkMode); // Save the new state to localStorage
    document.body.classList.toggle("dark", newDarkMode); // Add or remove the dark class on the body element
    document.body.classList.toggle("light", !newDarkMode); // Add or remove the light class on the body element
  };
  const navigate = () => {
    window.open("https://market.lawblocks.io", "_blank");
  };

  useEffect(() => {
    getTotalcontract();

    // Apply the saved theme from localStorage on initial render
    if (darkMode) {
      document.body.classList.add("dark");
      document.body.classList.remove("light");
    } else {
      document.body.classList.add("light");
      document.body.classList.remove("dark");
    }
  }, [darkMode]);

  const [totalContracts, settotalContracts] = useState(0);

  const getTotalcontract = async () => {
    var response = await axios.post(
      "https://marketapi.lawblocks.io/users/users/getTotalcontract"
    );
    settotalContracts(response.data.count);
  };

  return (
    <React.Fragment>
      <AppBar sx={{background: "#000000"}} className="header_padidng">
        <div className="link_top_section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <p className="m-0 text-left">
                  LBT Smart Contract Address :{" "}
                  <a
                    target="_blank"
                    href="https://xdcscan.com/address/xdc05940b2df33d6371201e7ae099ced4c363855dfe"
                  >
                    xdc05940b2df33d6371201e7ae099ced4c363855dfe
                    <i className="ri-external-link-line"></i>
                  </a>
                </p>
              </div>
              <div className="col-lg-6">
                <p className="m-0 text-right">
                  Total Contracts Uploaded :{" "}
                  <span>{164268 + totalContracts}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Toolbar>
          <div className="imag">
            <Link className="nav_brand" to="/">
              {" "}
              <img
                src={require("../../Image/banner/dark_logo.webp")}
                className="blackshow"
              />
              <img
                src={require("../../Image/law-Blocks-logo-dark.png")}
                className="whiteshow"
              />
            </Link>
          </div>
          {isMatch ? (
            <>
              <DrawerComp />
            </>
          ) : (
            <>
              <div className="nav_link_section d-flex align-items-center mr-2">
                <a href="/about-us" class="">
                  About us
                </a>
                <div class="dropdown itempadding">
                  <a
                    href=""
                    class=" dropdown-toggle"
                    id="dropdownMenuButton"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Features
                  </a>

                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      href="/ai-legal-document-generator"
                      class="dropdown-item"
                    >
                      AI Legal Documents{" "}
                    </a>
                    <a href="/smart-legal-contracts" class="dropdown-item">
                      Smart Legal Contracts
                    </a>
                    <a href="/digital-signature" class="dropdown-item">
                      Digital Signature
                    </a>
                    <a
                      href="/secure-document-storage-blockchain"
                      class="dropdown-item"
                    >
                      Uploading Blockchain
                    </a>
                    <a href="/ai-legal-chat" class="dropdown-item">
                      AI Chat
                    </a>
                    <a href="/Mediation " class="dropdown-item">
                      Mediation
                    </a>
                    <a href="/arbitration" class="dropdown-item">
                      Arbitration{" "}
                    </a>
                    <a href="/laywer-marketplace" class="dropdown-item">
                      Marketplace for Lawyers
                    </a>
                  </div>
                </div>
                <a href="/blog" class="">
                  Blog
                </a>
                <a href="/contact-us" class="">
                  Contact Us
                </a>
              </div>
              <Link onClick={navigate} className="Butn_header mr-3">
                Login
              </Link>
              <Link
                to=""
                className="Butn_header dark_change"
                onClick={toggleDarkMode}
              >
                {darkMode ? (
                  <i className="ri-moon-line"></i>
                ) : (
                  <i className="ri-sun-line"></i>
                )}
              </Link>
            </>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Header;
