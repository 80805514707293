import React, {useState, useEffect} from "react";
import Header from "../componant/HeaderNew";
import {Button} from "@mui/material";
import SocialLinks from "../componant/SocialLinks";
import {LocationOn, Email, Telegram} from "@mui/icons-material";

import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
import Footer from "../Footer";
import {Helmet} from "react-helmet";
// import Bgvedio from "../Image/globe-fiat-block.mp4";

import FAQAccordion from "../componant/FAQAccordionProps";

function Home() {
  const [isScrolled, setIsScrolled] = useState(false);
  // useEffect(() => {
  //   function handleScroll() {
  //     const fourthSection = document.querySelector("#section-4");
  //     const fourthSectionTop = fourthSection.offsetTop;
  //     const currentScrollPosition = window.pageYOffset + window.innerHeight;
  //     if (currentScrollPosition >= fourthSectionTop) {
  //       setIsScrolledToFourthSection(true);
  //     } else {
  //       setIsScrolledToFourthSection(false);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    function handleScroll() {
      const fourthSection = document.querySelector("#section-4");
      const header = document.querySelector("header");
      const {top} = fourthSection.getBoundingClientRect();
      const headerHeight = header.offsetHeight;
      setIsScrolled(top <= headerHeight);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const faqs = [
    {
      question: "What is a Digital Signature?",
      answer:
        "A Digital Signature is an electronic form of a signature that verifies the authenticity and integrity of a digital document. It provides a secure and legally binding way to sign documents electronically, ensuring that the signer is who they claim to be and that the document has not been altered.",
    },
    {
      question: "How does Law Blocks AI ensure the security of my documents?",
      answer:
        "Law Blocks AI ensures the security of your documents through end-to-end encryption, multi-factor authentication, and an audit trail. End-to-end encryption protects your data from unauthorized access, while multi-factor authentication verifies the identity of each signer. The audit trail provides a comprehensive record of the signing process, ensuring transparency and accountability.",
    },
    {
      question: "Is it easy to upload documents on Law Blocks AI?",
      answer:
        "Yes, the process is straightforward and user-friendly, ensuring even those with minimal technical expertise can easily upload documents.",
    },
    {
      question: "How secure are the documents stored on the blockchain?",
      answer:
        "Documents are encrypted and stored with an immutable record, ensuring they are tamper-proof and secure.",
    },
    {
      question: "Can I access my documents anytime?",
      answer:
        "Yes, documents stored on Law Blocks AI can be accessed quickly and efficiently, from anywhere in the world.",
    },
    {
      question: "What types of documents can be stored?",
      answer:
        "Law Blocks AI supports various file formats, making it suitable for legal contracts, medical records, financial documents, and more.",
    },
    {
      question: "Is there customer support available?",
      answer:
        "Yes, Law Blocks AI offers comprehensive support to assist with any questions or issues.",
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const navigate = () => {
    window.open("https://market.lawblocks.io", "_blank");
  };

  return (
    <div className={isScrolled ? "header scrolled" : "header color_white"}>
      <Header />
      <Helmet>
        <title>Join Community</title>
      </Helmet>
      <main>
        {/* <SectionWithVideoBackground /> */}
        <section
          className="section_banner_hero new_Section_AIdoc  bg-gray_section-hero heaight_excahnge section_fixed_height_sedcion banner_vierutal_hero height_contact new_hig"
          id="section-4"
        >
          {/* <div className="purble"></div>
          <div className="yellow"></div>
          <div className="green"></div> */}

          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12} className="">
                <div className="hero_seaction_law align-items-center  fixed_hero_height ">
                  <h1
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-center mb-2 w-100"
                  >
                    Community Support
                  </h1>
                  <p
                    className="text-center"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    Join the Law Blocks AI community and be part of the future
                    of legal innovation! <br />
                    Connect, collaborate, and explore AI-powered solutions with
                    us today!
                  </p>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className="section_defualt p-5">
          <Container
            maxWidth="lg"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="200"
          >
            <SocialLinks />
          </Container>
        </section>
      </main>
      <div></div>
      <Footer />
    </div>
  );
}

export default Home;
