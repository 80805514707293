import React from "react";
import {IconButton, Tooltip, Typography} from "@mui/material";
import {Box} from "@mui/system";
import {
  Language,
  Store,
  LinkedIn,
  YouTube,
  Twitter,
  Instagram,
  Reddit,
  Telegram,
  Discord,
  Email,
} from "@mui/icons-material";

const socialLinks = [
  {
    icon: <Language style={{fontSize: 60}} className="dark_icon" />,
    label: "Website",
    link: "https://lawblocks.io",
    username: "lawblocks.io",
  },
  {
    icon: <Store style={{fontSize: 60}} className="dark_icon" />,
    label: "Marketplace",
    link: "https://market.lawblocks.io",
    username: "market.lawblocks.io",
  },
  {
    icon: <LinkedIn style={{fontSize: 60}} className="dark_icon" />,
    label: "LinkedIn",
    link: "https://www.linkedin.com/company/lawblocks/",
    username: "@Law Blocks",
  },
  {
    icon: <YouTube style={{fontSize: 60}} className="dark_icon" />,
    label: "YouTube",
    link: "https://youtube.com/@lawblocks2982",
    username: "@lawblocks2982",
  },
  {
    icon: <Twitter style={{fontSize: 60}} className="dark_icon" />,
    label: "Twitter",
    link: "https://x.com/lawblockstoken",
    username: "@lawblockstoken",
  },
  {
    icon: <Instagram style={{fontSize: 60}} className="dark_icon" />,
    label: "Instagram",
    link: "https://instagram.com/law_blocks_ai",
    username: "law_blocks_ai",
  },
  {
    icon: <Reddit style={{fontSize: 60}} className="dark_icon" />,
    label: "Reddit",
    link: "https://www.reddit.com/user/LawBlocks/",
    username: "u/LawBlocks",
  },
  {
    icon: <Telegram style={{fontSize: 60}} className="dark_icon" />,
    label: "Telegram",
    link: "https://t.me/LawBlocks_updates",
    username: "@Law Blocks",
  },
  {
    icon: (
      <i
        class="ri-discord-fill dark_icon"
        style={{fontSize: 60, lineHeight: "60px"}}
      ></i>
    ),
    label: "Discord",
    link: "https://discord.gg/Vu5TsBG6",
    username: "Law Blocks",
  },
  {
    icon: <Email style={{fontSize: 60}} className="dark_icon" />,
    label: "Email",
    link: "mailto:info@lawblocks.io",
    username: "info@lawblocks.io",
  },
];

const SocialLinks = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: "40px",
        padding: "40px",
      }}
      className="text-decoratoin"
    >
      {socialLinks.map((social, index) => (
        <Box key={index} textAlign="center">
          <Tooltip title={social.label} arrow>
            <IconButton
              component="a"
              href={social.link}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: "black",
                textDecoration: "none",
                transition: "color 0.3s ease",
                "&:hover": {
                  color: "#5246e5", // Change this color to match your desired hover color
                },
              }}
            >
              {social.icon}
            </IconButton>
          </Tooltip>
          <Typography
            variant="subtitle1"
            component="p"
            style={{marginTop: "8px"}}
            className="color_text"
          >
            {social.username}
          </Typography>
        </Box>
      ))}
    </div>
  );
};

export default SocialLinks;
