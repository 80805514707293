import React, { useState, useEffect } from "react";
import Header from "../componant/HeaderNew";
import { Button } from "@mui/material";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
// import Bgvedio from "../Image/globe-fiat-block.mp4";

import FAQAccordion from "../componant/FAQAccordionProps";

function Home() {
  const [isScrolled, setIsScrolled] = useState(false);
  // useEffect(() => {
  //   function handleScroll() {
  //     const fourthSection = document.querySelector("#section-4");
  //     const fourthSectionTop = fourthSection.offsetTop;
  //     const currentScrollPosition = window.pageYOffset + window.innerHeight;
  //     if (currentScrollPosition >= fourthSectionTop) {
  //       setIsScrolledToFourthSection(true);
  //     } else {
  //       setIsScrolledToFourthSection(false);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    function handleScroll() {
      const fourthSection = document.querySelector("#section-4");
      const header = document.querySelector("header");
      const { top } = fourthSection.getBoundingClientRect();
      const headerHeight = header.offsetHeight;
      setIsScrolled(top <= headerHeight);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const faqs = [
    {
      question: "Is it easy to upload documents on Law Blocks AI?",
      answer:
        "Yes, the process is straightforward and user-friendly, ensuring even those with minimal technical expertise can easily upload documents.",
    },
    {
      question: "How secure are the documents stored on the blockchain?",
      answer:
        "Documents are encrypted and stored with an immutable record, ensuring they are tamper-proof and secure.",
    },
    {
      question: "Can I access my documents anytime?",
      answer:
        "Yes, documents stored on Law Blocks AI can be accessed quickly and efficiently, from anywhere in the world.",
    },
    {
      question: "What types of documents can be stored?",
      answer:
        "Law Blocks AI supports various file formats, making it suitable for legal contracts, medical records, financial documents, and more.",
    },
    {
      question: "Is there customer support available?",
      answer:
        "Yes, Law Blocks AI offers comprehensive support to assist with any questions or issues.",
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const navigate = () => {
    window.open("https://market.lawblocks.io", "_blank");
  };

  return (
    <div className={isScrolled ? "header scrolled" : "header"}>
      <Header />
      <Helmet>
        <title>Blockchain file Storage | Document Storage System</title>
        <meta
          property="og:title"
          content="Blockchain file Storage | Document Storage System
          "
        />
        <meta
          property="og:description"
          content=" Store your document securely on a blockchain with Law Blocks AI. Document storage system on blockchain. Try now.
           "
        />
        {/* <meta
          property="og:image"
          content="https://lawblocks.io/assets/secure-document-uploads-thumbnail.jpg"
        /> */}
        <meta
          property="og:url"
          content="https://lawblocks.io/blogdetails/secure-document-storage-blockchain"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <main>
        {/* <SectionWithVideoBackground /> */}
        <section
          className="section_banner_hero new_Section_AIdoc uploaddocs"
          id="section-4"
        >
          {/* <div className="purble"></div>
          <div className="yellow"></div>
          <div className="green"></div> */}

          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={6}>
                <div className="hero_seaction_law align-items-start ">
                  <h1
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left mb-2"
                  >
                    Blockchain Document Storage
                  </h1>

                  <p
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left m-0"
                  >
                    Secure your documents with Law Blocks AI. Enjoy tamper-proof
                    storage and easy access with blockchain technology
                  </p>
                  <Button
                    onClick={navigate}
                    className="laight_button border_radious_button"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                  >
                    Try for free
                  </Button>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={6}
                className="d-flex align-items-center justify-content-center"
              >
                <div
                  className="docs_image_content"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <img
                    alt="Secure Document Uploads with Law Blocks AI"
                    src={require("../../Image/banner/banner_upload.webp")}
                    className="img-fluid"
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>

        <section className="section_defualt mt-5">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="imag_widtjas"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      alt="Secure Document Uploads with Law Blocks AI"
                      src={require("../../Image/banner/overview_imag.webp")}
                      className="img-fluid"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>Overview of Law Blocks AI Upload Document</h2>
                    <p className="text-justify">
                      Law Blocks AI ensures document security by storing them on
                      the blockchain, making them tamper-proof and immutable.
                      Discover how our platform revolutionizes secure document
                      management with advanced blockchain technology.
                    </p>

                    <Button
                      onClick={navigate}
                      className="laight_button border_radious_button"
                    >
                      Get Started
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section className="section_defualt">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={5}>
                  <div
                    className="overvide align-items-start justify-content-start"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <span className="sapan_text">Our Key Features</span>
                    <h2>Secure, Transparent, and Tamper-Proof</h2>
                    <p>
                      Secure your documents with advanced encryption, immutable
                      records, and decentralized storage, ensuring transparency
                      and accountability.
                    </p>
                    <Button
                      onClick={navigate}
                      className="laight_button border_radious_button"
                    >
                      Get Started
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={7}>
                  <div
                    class="keyfetupload-grid"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <div class="keyfetupload-item">
                      <h2>1</h2>
                      <h3>Encryption</h3>
                      <p>
                        Every document uploaded to Law Blocks AI is encrypted
                        using state-of-the-art cryptographic techniques.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <h2>2</h2>
                      <h3>Immutable Records</h3>
                      <p>
                        Once a document is uploaded, it cannot be altered or
                        tampered with, ensuring a reliable and verifiable
                        record.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <h2>3</h2>
                      <h3>Decentralization</h3>
                      <p>
                        Our blockchain storage system is decentralized,
                        enhancing security and reducing the risk of data
                        breaches.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <h2>4</h2>
                      <h3>Audit Trails</h3>
                      <p>
                        Detailed audit trails provide transparency and
                        accountability for every document.
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section className="section_defualt bg-gray_section">
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className="d-flex justify-content-center mb-4"
              >
                <div
                  className=" overvide headeing_main"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <h2 className="text-center">
                    How to Upload Documents on <br />
                    Blockchain
                  </h2>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className="d-flex justify-content-center"
                data-aos="flip-left"
                data-aos-duration="500"
                data-aos-delay="400"
              >
                <div class="upload-steps">
                  <div class="upload-step">
                    <div class="icon">
                      <i class="ri-login-box-line"></i>
                    </div>
                    <h4>Sign Up</h4>
                    <p>
                      Begin by creating an account on the Law Blocks AI
                      platform. Our user-friendly interface makes the sign-up
                      process quick and easy.
                    </p>
                  </div>
                  <div class="upload-step">
                    <div class="icon">
                      <i class="ri-crosshair-line"></i>
                    </div>
                    <h4>Navigate</h4>

                    <p>
                      Once logged in, navigate to the "Upload Documents"
                      section. This feature is designed to be intuitive,
                      ensuring you can find and use it with ease.
                    </p>
                  </div>
                  <div class="upload-step">
                    <div class="icon">
                      <i class="ri-hand"></i>
                    </div>
                    <h4>Select Your Document</h4>

                    <p>
                      Choose the document you wish to upload from your device.
                      Law Blocks AI supports various file formats, ensuring
                      compatibility with your needs.
                    </p>
                  </div>
                  <div class="upload-step">
                    <div class="icon">
                      <i class="ri-file-upload-line"></i>
                    </div>
                    <h4>Upload and Store on Blockchain</h4>

                    <p>
                      Click "Upload" to initiate the process. Your document will
                      be securely encrypted and stored on the blockchain,
                      creating an immutable record.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className="section_defualt ">
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className="d-flex justify-content-center mb-4"
              >
                <div
                  className=" overvide headeing_main align-items-center"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <h2 className="text-center">
                    Working Process of AI-based Legal <br />
                    Smart Contracts
                  </h2>
                  <p className="text-center">
                    Law Blocks AI Platform generates, verifies, and securely
                    stores legal documents using AI, integrating with digital
                    wallets for <br />
                    seamless management. Documents are digitally signed and
                    uploaded to the blockchain for immutable storage, <br />
                    ensuring authenticity and accessibility.
                  </p>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className="d-flex justify-content-center"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="200"
              >
                <img
                  alt="Secure Document Uploads with Law Blocks AI"
                  src={require("../../Image/banner/main-flow.webp")}
                  className="img-fluid whiteshow"
                />
                <img
                  alt="Secure Document Uploads with Law Blocks AI"
                  src={require("../../Image/banner/darrkmode_flw.webp")}
                  className="img-fluid blackshow"
                />
              </Grid>
            </Grid>
          </Container>
        </section>
        {/* <section className="section_defualt bg-gray_section security_page">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <div className="overvide h-auto">
                    <h2>
                      Security Features of
                      <br /> Blockchain Document Storage
                    </h2>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={7}>
                  <div class="keyfetupload-grid">
                    <div class="keyfetupload-item">
                      <h2>1</h2>
                      <h3>Encryption</h3>
                      <p>
                        Every document uploaded to Law Blocks AI is encrypted
                        using state-of-the-art cryptographic techniques.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <h2>2</h2>
                      <h3>Immutable Records</h3>
                      <p>
                        Once a document is uploaded, it cannot be altered or
                        tampered with, ensuring a reliable and verifiable
                        record.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <h2>3</h2>
                      <h3>Decentralization</h3>
                      <p>
                        Our blockchain storage system is decentralized,
                        enhancing security and reducing the risk of data
                        breaches.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <h2>4</h2>
                      <h3>Audit Trails</h3>
                      <p>
                        Detailed audit trails provide transparency and
                        accountability for every document.
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={5}>
                  <div
                    className=" mt-3"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      src={require("../../Image/banner/lock_image.webp")}
                      className="img-fluid"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section> */}
        <section
          className="section_defualt "
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <div
                  className="overvide align-items-center"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <h2 className="text-center">
                    Benefits of Using Law Blocks AI <br />
                    for Document Storage
                  </h2>
                </div>
              </Grid>
            </Grid>
            <div class="features-container">
              <div class="feature-box">
                <div className="circule_radioue">
                  <img
                    alt="Secure Document Uploads with Law Blocks AI"
                    src={require("../../Image/banner/shield-check.webp")}
                  />
                </div>
                <h3>Reliability</h3>
                <p>
                  Law Blocks AI keeps your documents available whenever you need
                  them.
                </p>
              </div>
              <div class="feature-box">
                <div className="circule_radioue">
                  <img
                    alt="Secure Document Uploads with Law Blocks AI"
                    src={require("../../Image/banner/inovative.webp")}
                  />
                </div>
                <h3>Scalability</h3>
                <p>
                  Our platform grows with you, from individual users to large
                  enterprises.
                </p>
              </div>
              <div class="feature-box">
                <div className="circule_radioue">
                  <img
                    alt="Secure Document Uploads with Law Blocks AI"
                    src={require("../../Image/banner/uiser.webp")}
                  />
                </div>
                <h3>User-Friendly Interface</h3>
                <p>
                  Uploading and managing documents is simple with our
                  user-friendly interface.
                </p>
              </div>
              <div class="feature-box">
                <div className="circule_radioue">
                  <img
                    src={require("../../Image/banner/user-headset.webp")}
                    alt="Secure Document Uploads with Law Blocks AI"
                  />
                </div>
                <h3>Comprehensive Support</h3>
                <p>
                  Our support team is always ready to help, ensuring a seamless
                  experience.
                </p>
              </div>
            </div>
          </Container>
        </section>

        <section
          className="section_defualt bg_selcton_faq"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={6} className=" mb-4">
                <div className=" overvide headeing_main align-items-start justify-content-start">
                  {/* <span className="lable_badge">FAQ</span> */}
                  <h2 className="text-captailz">Frequently asked questions</h2>
                  <p>
                    Here are some common questions and their answers to help you
                    understand how Law Blocks AI can assist you.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={6} className=" mb-4">
                <FAQAccordion faqs={faqs} />
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
      <div></div>
      <Footer />
    </div>
  );
}

export default Home;
