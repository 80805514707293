import React from "react";

const PartnerItem = ({ wrapperClass, imgClassName, logo, brandName, url }) => {
    const content = (
        <div className={wrapperClass}>
            <img src={logo} alt="brand" className={imgClassName} />
            {brandName && <p className="partner-label">{brandName}</p>}
        </div>
    );

    return url ? (
        <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}
        >
            {content}
        </a>
    ) : (
        content
    );
};

export default PartnerItem;
