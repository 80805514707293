import React, { useState, useEffect } from "react";
import Header from "../componant/HeaderNew";
import partners from "./PartnersData";

import {
  Container,
  Grid,
} from "@mui/material";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
import PartnerItem from "./PartnerItem";

function Partnerspage() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const fourthSection = document.querySelector("#section-4");
      const header = document.querySelector("header");
      const { top } = fourthSection.getBoundingClientRect();
      const headerHeight = header.offsetHeight;
      setIsScrolled(top <= headerHeight);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={isScrolled ? "header scrolled" : "header color_white"}>
      <Header />
      <Helmet>
        <title>Our Partners</title>
      </Helmet>
      <main>
        <section
          className="section_banner_hero new_Section_AIdoc  bg-gray_section-hero heaight_excahnge section_fixed_height_sedcion banner_vierutal_hero height_contact new_hig"
          id="section-4"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12} className="">
                <div className="hero_seaction_law align-items-center  fixed_hero_height ">
                  <h1
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-center mb-2 w-100"
                  >
                    Our Partners
                  </h1>
                  <p
                    className="text-center"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    We collaborate with top tech and legal innovators to
                    integrate AI, blockchain, and legal tech, transforming legal
                    services with enhanced efficiency, security, and
                    transparency. Together, we empower firms to modernize and
                    thrive.
                  </p>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className="section_defualt p-5">
          <Container
            maxWidth="lg"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="200"
          >
            <Grid container spacing={3} className="justify-content-center">
              {partners.map((partner, index) => (
                <Grid item xs={12} sm={4} md={4} lg={3} key={index}>
                  <PartnerItem {...partner} />
                </Grid>
              ))}
            </Grid>
          </Container>
        </section>
      </main>
      <div></div>
      <Footer />
    </div>
  );
}

export default Partnerspage;
